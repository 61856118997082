<template>
  <div>
    <CategoryProductsComponent
      v-if="outletData"
      :partnerCategories="partnerCategories"
      :products="products"
      :setSelectCategory="setSelectCategory"
      :getMenu="getMenu"
      :activeProduct="activeProduct"
      :menuLoading="menuLoading"
      :selectCategory="selectCategory"
      :menu="menu"
      :addMenu="addMenu"
      :filterProduct="filterProduct"
      :addMenuCatgory="addMenuCatgory"
      :outletData="outletData"
      :getPartnerMenuCategories="getPartnerMenuCategories" />
      <AddCategoryDialog
        :dialogVisible="categoryDialogVisible"
        :closeCategoryDialog="closeCategoryDialog"
        :addCategory="addCategory"
        :menuCategoryList="menuCategoryList"
      ></AddCategoryDialog>
  </div>
</template>

<script>
import CategoryProductsComponent from '../CategoryProductsComponent.vue'
import mAxios from '../../../../../helper/axiosInstance'
import AddCategoryDialog from '../AddCategoryDialog.vue'
export default {
  name: 'CategoryProducts',
  components: {
    CategoryProductsComponent,
    AddCategoryDialog
  },
  data () {
    return {
      partnerCategories: [],
      categoryDialogVisible: false,
      menuCategoryList: [],
      outletData: null,
      loading: false,
      menuLoading: false,
      menu: null,
      products: [],
      backupData: [],
      total_active_products: 0,
      total_inactive_products: 0,
      selectCategory: null,
      isAllFood: false,
      propertiesData: [],
      selectedProducts: [],
      currentOutlet: null
    }
  },
  created () {
    this.getOutletData(this.$route.query.outletId)
    this.getPartnerMenuCategories(this.$route.query.outletId)
    this.getMenus(this.$route.query.outletId)
    this.currentOutlet = this.$root.chosenOutlets.filter(outlet => outlet._id === this.$route.query.outletId)[0]
    window.scrollTo(0, 0)
  },
  methods: {
    async addCategory (categoryForm) {
      const payload = {
        menu_category_mon: categoryForm.menu_category_mon,
        menu_category_eng: categoryForm.menu_category_eng,
        outlet_id: this.currentOutlet._id
      }
      await mAxios
        .post('outlet/addMenuCategoryOutlet', payload)
        .then(async response => {
          this.loadUpload = true
          if (response.data.status === 'success') {
            this.$notify({
              title: 'Амжилттай хадгалагдлаа',
              message: response.data.data.message,
              type: 'success',
              position: 'bottom-left'
            })
            this.getPartnerMenuCategories(this.$route.query.outletId)
            // this.$router.go()
            this.loadUpload = false
          } else {
            this.$notify({
              title: 'Алдаа',
              message: response.data.message,
              type: 'error',
              position: 'bottom-left'
            })
          }
          this.menuLoading = false
        })
      this.categoryDialogVisible = false
    },
    addMenuCatgory () {
      this.getMenuCategories()
      this.categoryDialogVisible = true
    },

    closeCategoryDialog () {
      this.categoryDialogVisible = false
    },

    getMenuCategories () {
      mAxios
        .post('getMenuCategories', {
          service_list:
            this.outletData &&
            Object.prototype.hasOwnProperty.call(
              this.outletData,
              'service_list'
            )
              ? this.outletData.service_list
              : ['food']
        })
        .then(categoriesResponse => {
          if (categoriesResponse.data.status === 'success') {
            this.menuCategoryList = categoriesResponse.data.data
          }
        })
    },

    filterProduct (text) {
      const search = text.toLowerCase()
      if (search === '') {
        this.products = this.backupData
      } else {
        this.products = this.products.filter(
          (item) => {
            if (
              item?.name_mon
                .toLowerCase()
                .indexOf(search) > -1
            ) {
              return item
            }
          }
        )
      }
    },
    getPartnerMenuCategories (id) {
      mAxios
        .post('product/getPartnerMenuCategories', {
          outlet_id: id
        })
        .then(categoriesResponse => {
          if (categoriesResponse.data.status === 'success') {
            categoriesResponse.data.data.forEach(category => {
              category.is_select = false
            })
            this.partnerCategories = categoriesResponse.data.data
            this.getMenus(id)
          }
        })
    },
    getOutletData (outletId) {
      this.loading = true
      const includeFields = [
        '_id',
        'full_name_mon',
        'full_name_eng',
        'service_list',
        'menu_categories',
        'outlet_name',
        'phone_list',
        'img_url'
      ]
      mAxios
        .post(`/outlet/${outletId}/get`, { include_fields: includeFields })
        .then(res => {
          if (res.data.status === 'success') {
            this.outletData = res?.data?.data
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(e => {
          this.$message({
            message: e.response.data.message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    generateProductCount () {
      let inActiveProduct = 0
      let activeProduct = 0
      this.products.forEach(product => {
        if (product.is_active) {
          activeProduct++
        } else {
          inActiveProduct++
        }
      })
      this.total_active_products = activeProduct
      this.total_inactive_products = inActiveProduct
    },
    setSelectCategory (category) {
      category.is_select = !category.is_select
      this.selectCategory = category
    },
    getMenu (id) {
      this.isAllFood = false
      this.loading = true
      this.menu = null
      mAxios
        .get(`product/getProductById/${id}`)
        .then(menuResponse => {
          if (menuResponse.data.status === 'success') {
            this.menu = menuResponse.data.data
            this.menu._id = this.currentOutlet._id
            this.menu.user = this.outletData.outlet_name
            this.menu.product_id = id
            this.propertiesData = this.menu.properties
            this.$router.push({
              name: 'products',
              query: {
                productId: id,
                outletId: this.currentOutlet._id
              }
            })
          }
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async activeProduct (data) {
      data.user = this.outletData.outlet_name
      data.product_id = data.pkey
      data._id = data.outlet_id
      await mAxios.post('product/updateMenu', data).then(response => {
        if (response.data.status === 'success') {
          this.$notify({
            title: 'Амжилттай',
            message: data.is_active ? response.data.data.message : 'Та борлуулалтаа алдаж байна.',
            type: data.is_active ? 'success' : 'warning',
            position: 'bottom-left'
          })
          this.$forceUpdate()
        } else {
          this.$notify({
            title: 'Алдаа',
            message: response.data.message,
            type: 'error',
            position: 'bottom-left'
          })
        }
        this.loading = false
      })
    },
    addMenu (item) {
      this.$router.push({ name: 'products', query: { outletId: this.currentOutlet._id }, params: { item: item } })
    },
    getMenus (id) {
      this.menuLoading = true
      this.menu = null
      mAxios.get(`product/${id}`).then(response => {
        if (response.data.status === 'success') {
          response.data.data.forEach(product => {
            product.is_checked = false
          })
          this.products = response.data.data
          this.backupData = JSON.parse(JSON.stringify(response.data.data))
          this.generateProductCount()
        } else {
          this.$notify({
            title: 'Алдаа',
            message: response.data.message,
            type: 'error',
            position: 'bottom-left'
          })
        }
        this.menuLoading = false
      })
    },
    setProduct (product) {
      if (product.is_checked) {
        this.selectedProducts.push(product)
      } else {
        this.selectedProducts = this.selectedProducts.filter(
          el => el.pkey !== product.pkey
        )
      }
      if (this.selectedProducts.length === 0) {
        this.copyProductDialog = false
      }
    }
  }
}
</script>

<style>
</style>
